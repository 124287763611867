<template>
  <div>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper>
      <template v-slot:content>
        <div style="margin-bottom: 20px">
          <h1>홍보실</h1>
        </div>
        <div>
          <div class="header">
            <div class="title">{{ getItemById?.title }}</div>
            <div class="created-date">등록일 {{ getItemById?.createdDate }}</div>
          </div>
          <div class="content">
            <video controls height="480" width="850">
              <source :src="getItemById?.src" type="video/mp4" />
            </video>
          </div>
        </div>
        <div class="footer" v-html="getItemById?.content"></div>
        <div class="actions">
          <router-link to="/pr/library">홍보센터 목록</router-link>
        </div>
      </template>
    </content-wrapper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from 'vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';

export default defineComponent({
  components: {
    BreadcrumbWrapper,
    ContentWrapper
  },
  props: {
    id: {
      type: Number
    }
  },
  setup(props) {
    const items = reactive([
      {
        id: 1,
        title: '[MAGIC4 임상동영상] Magic4 사용법',
        content: 'Magic4 사용법 임상동영상',
        src: 'https://osstem-web-data.s3.ap-northeast-2.amazonaws.com/BRAND/BRAND001/publicRelations/Magic4%2B_240715.mp4',
        createdDate: '2024-08-21'
      },
      {
        id: 2,
        title: '[MAGICTOC 임상동영상] MagicToc 사용법',
        content: 'MagicToc 사용법 임상동영상',
        src: 'https://osstem-web-data.s3.ap-northeast-2.amazonaws.com/BRAND/BRAND001/publicRelations/MagicTOC.mp4',
        createdDate: '2024-08-21'
      }
      // {
      //   id: 3,
      //   title: '[MAGIC Denture 임상동영상] 6. 고딕아치 트레이싱 및 Bite 채득',
      //   content: '고딕아치 트레이싱 및 Bite 채득 임상동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001005/46/o_1cqvlef503l0o6c17p9o731hona.mp4',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 4,
      //   title: '[MAGIC Denture 임상동영상] 5. Center pin 조정',
      //   content: 'Center pin 조정 임상동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001005/45/o_1cqvlap3e5hm1dua1rh2ia018vua.mp4',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 5,
      //   title: '[MAGIC Denture 임상동영상] 4. VD(수직고경)측정을 위한 트레이 분리',
      //   content: 'VD(수직고경)측정을 위한 트레이 분리 임상동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001005/43/o_1cqvku6uffsc7qb3qu10sg1tca.mp4',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 6,
      //   title: '[MAGIC Denture 임상동영상] 3. 노출된 트레이 부분 다듬기',
      //   content: '노출된 트레이 부분 다듬기 임상동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001005/42/o_1cqvk5hiv17uog276d317h517s1a.mp4',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 7,
      //   title: '[MAGIC Denture 임상동영상] 2. Lightbody 보더몰딩',
      //   content: 'Lightbody 보더몰딩 임상동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001005/41/o_1cqvjmbme14kan1n1ga41d5l3a.mp4',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 8,
      //   title: '[MAGIC Denture 임상동영상] 1. Heavybody 보더몰딩',
      //   content: 'Heavybody 보더몰딩 임상동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001005/40/o_1cqvjh50f1111v8tdbj1gsn1j3pa.mp4',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 9,
      //   title: '임상증례Case-01',
      //   content: '실제 환자 증례 입니다.',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001001/21/o_1bvh3bqbv64pb331ngdc54rbja.mp4',
      //   createdDate: '2017-10-27'
      // },
      // {
      //   id: 10,
      //   title: 'MAGIC Positoiner bonding 동영상',
      //   content: `<div>
      //       <p><strong>1. 정확한 브라켓 포지셔닝</strong> : <span>3D S/ W를 활용한 정밀한 위치 제어</span><br/></p>
      //       <p>Digital Set-Up 후 브라켓 위치 선정, Initial 치아 브라켓 위치 재연</p>
      //       <p><br/></p>
      //       <p><strong>2. 체어타임 감소</strong>	: <span>기존 브라켓 본딩에 비해 1/3 시술시간 감소</span><br/></p>
      //       <p>Digital Set-Up 후 브라켓 위치 선정, Initial 치아 브라켓 위치 재현</p>
      //       <p><br/></p>
      //       <p><strong>3. 재사용 가능</strong> : <span>브라켓 탈락 시, 트레이 재사용을 통해 정확한 위치 재연 가능</span><br/></p>
      //       <p>브라켓 탈락 시 개별트레이 사용으로 정확한 위치 재연</p>
      //       <p><br></p>
      //       <p><strong>4. 입안에 빠르고 정확하게 위치</strong><br/></p>
      //       <div class="img_left_box">
      //         <img src="${require('@/assets/images/pro_img18.png')}">
      //         <p><br/></p>
      //         <p><br/></p>
      //         <p><br/></p>
      //         <p>
      //           매직 포지셔너는 교정기의 간접 본딩을위한 디지털 방식으로<br/>
      //           계획된 치료 시스템입니다.<br/>
      //           브래킷 위치의 가상 계획은 사전에 수행됩니다.<br/>
      //           혁신적인 트레이의 도움으로 브래킷을 환자의 입안에<br/>
      //           빠르고 정확하게 위치시킵니다.
      //         </p>
      //       </div>
      //     </div>`,
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001002/20/f45aa1a8-a780-4f69-8a05-f50dfdec4829.mp4',
      //   createdDate: '2017-10-26'
      // },
      // {
      //   id: 11,
      //   title: 'MAGIC Retainer bonding 동영상',
      //   content: `<div>
      //       <p><br/></p>
      //       <p><br/></p>
      //       <p><br/></p>
      //       <p><strong>1. 탁월한 치열 유지 효과</strong> : <span>형상기억합금(NiTi) 소재 이용</span><br/></p>
      //       <p><br/></p>
      //       <p>형상기업합금 (NiTi) 소재를 이용하여 높은 탄성과 형태 유지력 제공</p>
      //       <p><br/></p>
      //       <p><br/></p>
      //       <p><br/></p>
      //       <p><strong>2. 장치 적합도 우수</strong> : <span>치아 설면 형태의 정밀한 재연</span><br/></p>
      //       <p><br/></p>
      //       <p>3D 레이저 밀링을 통한 정밀 가공</p>
      //       <p><br/></p>
      //       <p><br/></p>
      //       <p><br/></p>
      //       <p><strong>3. 쉬운장착</strong> : <span>트랜스지그 형태로 쉽게 장착 가능</span><br/></p>
      //       <p><br/></p>
      //       <p>전치부에 트랜스지그를 사용하여 정확한 위치 포지션 가능</p>
      //       <p><br/></p>
      //     </div>`,
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/BRAND/BRAND001/BRAND001003/19/e7d0f091-7c94-4a33-b67f-c5d7a304687d.mp4',
      //   createdDate: '2017-10-26'
      // }
    ]);

    const getItemById = computed(() => items.find(v => v.id === props.id));

    return { items, getItemById };
  }
});
</script>

<style scoped lang="scss">
@import './LibraryDetailView.scss';
</style>
